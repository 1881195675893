import { useCallback, useEffect, useState } from 'react';

import { useApi } from '../common/api/hooks/useApi';
import { useClearCache } from '../common/api/hooks/useClearCache';
import { AccessTokenPayload, AuthUser, getAuthUser, patchAuthUser } from '../common/session';
import { useTargetCompanyIdQueryParam } from './useTargetCompanyIdQueryParam';

export type AuthUserMutator = (user?: Partial<AuthUser>) => Promise<void>;

export function useAuthUser(): (
  | { authUser: undefined; isLoading: true }
  | { authUser: AuthUser | null; isLoading: false }
) & { mutate: AuthUserMutator } {
  const [authUser, setAuthUser] = useState<AuthUser | null>();

  const { get } = useApi('auth');
  const clearCache = useClearCache();
  const [targetQueryParam, setTargetQueryParam] = useTargetCompanyIdQueryParam();

  const mutate = useCallback(
    async (values?: Partial<AuthUser>) => {
      if (!authUser?._id) {
        return;
      }

      let mutations = values;
      if (!mutations) {
        ({ data: mutations } = await get<AccessTokenPayload>('me'));
      }

      setAuthUser((currentUser) => {
        if (!currentUser) {
          return null;
        }

        const newUser = { ...currentUser, ...mutations };
        [{ _id: newUser.companyId }] = newUser.companies;

        patchAuthUser(newUser);
        return newUser;
      });
    },
    [authUser?._id, get],
  );

  useEffect(() => {
    const user = getAuthUser();

    if (user) {
      const { companies, targetCompanyId: userTargetId } = user;

      const queryTargetId = targetQueryParam?.value;
      if (
        queryTargetId &&
        queryTargetId !== userTargetId &&
        companies.some(({ _id: companyId }) => companyId === queryTargetId)
      ) {
        user.targetCompanyId = queryTargetId;
        patchAuthUser(user);
        clearCache();
      }

      if (queryTargetId !== user.targetCompanyId) {
        setTargetQueryParam(user.targetCompanyId);
      }
    }

    setAuthUser(user);
  }, [clearCache, setTargetQueryParam, targetQueryParam?.value]);

  if (authUser === undefined) {
    return { authUser: undefined, isLoading: true, mutate };
  }

  return { authUser, isLoading: false, mutate };
}
